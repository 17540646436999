
import React from 'react';
import SignUp from "../SignUp";

const Home = () => {
    return (
        <SignUp/>
    )
}

export default Home;